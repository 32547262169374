<template>
  <section>
    <!-- HEADER DETAIL -->
    <div class="card">
      <div class="card-content">
        <p @click="back()" class="back-btn title is-6" style="margin-bottom: 10px;"> <b-icon icon='arrow-left'/> Kembali ke list</p>
        <h1 style="padding-left: 10px;" class="title is-4">Detail Penawaran - {{ title }} (#{{ this.$route.params.idPenawaran }})</h1>
      </div>
    </div>

    <!-- TABS DETAIL PENAWARAN -->
    <div class="columns">
      <div class="column">
        <div class="tabs is-centered is-boxed">
          <ul>
            <li :class="{'is-active' : activeTab === 0}">
            <a @click="selectTab(0)">
              <span>Detail Penawaran</span>
            </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- DETAIL PENAWARAN -->
    <div v-if="detail !== null">
      <div class="tile is-ancestor" ref="element" v-show="activeTab === 0">
        <div class="tile is-vertical is-5">
          <div class="tile">
            <div class="tile is-parent is-vertical">
              <article class="tile is-child notification">
                <p class="title">Deskripsi Penawaran</p>
                <div class="content">
                  <div class="columns is-mobile mb-4">
                    <div class="column">
                      <div class="columns is-mobile">
                        <div class="column">
                          <h1 class="title is-size-5 is-size-6-mobile mt-3">Penawaran ID #{{ detail.id }}</h1>
                        </div>
                        <div v-show="detail.status === 1" class="column is-align-self-center">
                          <span class="tag" :class="{'is-warning': detail.is_active === 0, 'is-primary': detail.is_active === 1}">
                            <p class="has-text-weight-bold" v-if="detail.is_active === 0"> BELUM AKTIVASI</p>
                            <p class="has-text-weight-bold" v-else-if="detail.is_active === 1"> AKTIF</p>
                            <p class="has-text-weight-bold" v-else> - </p>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="columns is-mobile">
                    <div class="column">
                      <div>
                        <span class="has-text-weight-bold">Nomor Penawaran :</span> {{ detail.reference_code }} <br/>
                        <span class="has-text-weight-bold">Nama Client :</span> {{ detail.client_name }} <br/>
                        <span class="has-text-weight-bold">Tanggal Pembuatan Penawaran :</span> {{ convDate(detail.created_at) }} <br/>
                        <span v-show="detail.is_active === 1" class="has-text-weight-bold">Diaktifkan Oleh :</span>
                          {{ detail.activated_by }} <br/>
                      </div>
                      <br/>
                      <div>
                        <span class="has-text-weight-bold">Status Penawaran:</span> &nbsp;
                        <span class="tag" :class="tagStatus">
                          <p class="has-text-weight-bold" v-if="detail.status === 0"> PENDING</p>
                          <p class="has-text-weight-bold" v-else-if="detail.status === 1"> APPROVED</p>
                          <p class="has-text-weight-bold" v-else> REJECTED</p>
                        </span> &nbsp;
                      </div>
                      <br/>
                      <div v-show="detail.status === 1 && detail.is_active === 0">
                        <div v-if="!menuAccess('/penawaran/detail')">
                          <span class="has-text-weight-bold">Apakah anda ingin mengaktifkan penawaran ini?</span> <br/><br/>
                          <div class="has-text-centered">
                            <b-button icon-left="check" @click="showAktivasiModal(detail.id)" type="is-info"> AKTIFKAN</b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
              <article class="tile is-child notification">
                <p class="title">Grand Total</p>
                <div class="content">
                  <div class="columns is-mobile">
                    <div class="column is-align-self-center">
                      <h1 class="title is-size-5 has-text-info">Rp.{{ parseInt(detail.amount) | numFormat }}</h1>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
        <div class="tile is-vertical is-7">
          <div class="tile">
            <div class="tile is-parent is-vertical">
              <article class="tile is-child notification">
                <p class="title">Rincian Tukang & Material</p>
                <div class="content">
                  <!-- TABLE DETAIL TUKANG -->
                  <div class="columns is-mobile mb-4">
                    <div class="column">
                      <b-table
                        :striped="true"
                        :hoverable="true"
                        :data="detail.worker_details"
                        default-sort="id"
                        :centered="true"
                        backend-pagination>
                        <b-table-column label="Tukang" field="skill_name" v-slot="props" colspan="4">
                          <small class="has-text-grey is-abbr-like">
                            <div class="columns is-vcentered">
                              <div class="column is-one-quarter">
                                <p class="image is-64x64">
                                  <img :src="props.row.image_uri" />
                                </p>
                              </div>
                              <div class="column">
                                <small class="has-text-grey is-abbr-like has-text-centered">{{ props.row.skill_name }}</small> -
                                <small class="has-text-grey is-abbr-like has-text-centered">{{ props.row.sesi_name }}</small>
                              </div>
                            </div>
                          </small>
                        </b-table-column>
                        <b-table-column label="Jumlah Tukang" v-slot="props">
                          <small class="has-text-grey is-abbr-like has-text-centered" :title="props.row.qty">
                            {{ props.row.qty }}
                          </small>
                        </b-table-column>
                        <b-table-column label="Jumlah Hari" field="duration">
                          <small class="has-text-grey is-abbr-like" :title="detail.duration">
                            {{ detail.duration }}
                          </small>
                        </b-table-column>
                        <b-table-column label="Harga" field="price" v-slot="props">
                          <small class="has-text-grey is-abbr-like" :title="props.row.price">
                            Rp.{{ props.row.price | numFormat }}
                          </small>
                        </b-table-column>
                        <b-table-column label="Harga Total" v-slot="props">
                          <small class="has-text-grey is-abbr-like" :title="props.row.amount">
                            Rp.{{ props.row.amount | numFormat }}
                          </small>
                        </b-table-column>

                        <template #footer>
                          <div class="has-text-right">
                            <p class="has-text-weight-bold">Total: Rp.{{ totalAllTukang | numFormat }}</p>
                          </div>
                        </template>
                      </b-table>
                    </div>
                  </div>
                  <!-- TABLE DETAIL MATERIAL -->
                  <div class="columns is-mobile mb-4">
                    <div class="column">
                      <b-table
                        :striped="true"
                        :hoverable="true"
                        :data="detail.material_details"
                        centered="true"
                        default-sort="id"
                        backend-pagination>
                        <b-table-column label="Produk" field="product_name" v-slot="props">
                          <small class="has-text-grey is-abbr-like">
                            <div class="columns is-vcentered">
                              <div class="column is-one-quarter">
                                <p class="image is-128x128">
                                  <img :src="props.row.product_image" />
                                </p>
                              </div>
                              <div class="column is-full">
                                <span class="has-text-right is-size-7">{{ props.row.product_name }}</span>
                              </div>
                            </div>
                          </small>
                        </b-table-column>
                        <b-table-column label="Jumlah" v-slot="props">
                          <small class="has-text-grey is-abbr-like" :title="props.row.qty">
                            {{ props.row.qty }}
                          </small>
                        </b-table-column>
                        <b-table-column label="UOM" field="unit" v-slot="props">
                          <small class="has-text-grey is-abbr-like" :title="props.row.unit">
                            {{ props.row.unit }}
                          </small>
                        </b-table-column>
                        <b-table-column label="Harga" field="price" v-slot="props">
                          <small class="has-text-grey is-abbr-like" :title="props.row.price">
                            Rp.{{ props.row.price | numFormat }}
                          </small>
                        </b-table-column>

                        <template #footer>
                          <div class="has-text-right">
                            <p class="has-text-weight-bold">Total: Rp.{{ totalAllMaterial | numFormat }}</p>
                          </div>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MODAL AKTIVASI PENAWARAN -->
    <b-modal v-model="aktivasiModal" has-modal-card trap-focus :destroy-on-hide="false">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Aktivasi Penawaran</p>
          <button class="delete" aria-label="close" @click.prevent="close()"></button>
        </header>
        <section class="modal-card-body">
          <div class="columns is-mobile">
            <div class="column is-bordered">
              <span class="has-text-weight-bold">ID Penawaran :</span> {{ offerId }} <br/>
              <span class="has-text-weight-bold">Nomor Penawaran :</span> {{ detail.reference_code }} <br/>
              <span class="has-text-weight-bold">Grand Total Penawaran :</span> Rp.{{ detail.amount | numFormat }}
            </div>
          </div>

          <b-field label="Estimasi Pengerjaan Selesai">
            <b-numberinput :min="1" v-model="estimateCompleted" controls-rounded rounded></b-numberinput>
          </b-field>

          <div class="columns mt-4">
            <div class="column has-text-centered">
              <span class="has-text-weight-bold mb-1"> Tentukan Jadwal Pengerjaan Project </span>
            </div>
          </div>

          <div class="columns is-mobile">
            <div class="column">
              <v-date-picker
                :min-date='minDateOrder'
                @input="onDateRangeChange" value="range"
                :attributes="dateAttributes"
                :columns="layoutCalender.columns"
                :rows="layoutCalender.rows"
                :is-expanded="layoutCalender.isExpanded"
                is-range/>
            </div>
          </div>
          <button class="button is-primary is-fullwidth" @click="submitAktivasi()" :disabled="days.length === 0"> Send </button>
        </section>
      </div>
    </b-modal>
  </section>
</template>

<script>

// import axios from 'axios'
import moment from 'moment'
// import loadash from 'lodash'
import { mapGetters } from 'vuex'
import guard from '../../routerGuard'

export default {
  name: 'PenawaranDetail',
  components: {},
  data () {
    return {
      activeTab: 0,
      penawaran: null,
      notesProyek: null,
      title: '',
      statusPenawaran: null,
      isFullPage: true,
      aktivasiModal: false,
      days: [],
      minDateOrder: null,
      offerId: null,
      estimateCompleted: null
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      roles: 'auth/roles',
      rolePath: 'auth/rolePath',
      detail: 'penawaran/getDetailPenawaran',
      status: 'penawaran/getStatusPenawaran'
    }),
    totalAllMaterial () {
      let x = 0
      const data = this.detail
      if (data !== null) {
        data.material_details.forEach((row) => {
          x += row.price
        })
      }
      return x
    },
    totalAllTukang () {
      let x = 0
      const data = this.detail
      if (data !== null) {
        data.worker_details.forEach((row) => {
          x += row.total
        })
      }
      return x
    },
    tagStatus () {
      const data = this.detail
      return {
        'is-danger': data.status === 2,
        'is-warning': data.status === 0,
        'is-success': data.status === 1
      }
    },
    hideButton () {
      return {
        'is-hidden': this.detail.is_active === 1
      }
    },
    dates () {
      return this.days.map(day => day.date)
    },
    dateAttributes () {
      return this.dates.map(date => ({
        highlight: true,
        dates: date
      }))
    },
    layoutCalender () {
      return this.$screens({
        default: {
          columns: 1,
          rows: 1,
          isExpanded: true
        },
        lg: {
          columns: 1,
          rows: 1,
          isExpanded: true
        }
      })
    }
  },
  methods: {
    dateMin () {
      this.minDateOrder = moment().add(1, 'days').toDate()
      const now = moment()
      if (now.hour() >= 18) {
        this.minDateOrder = moment().add(2, 'days').toDate()
      }
    },
    onDateRangeChange (day) {
      this.days = []
      this.days.push({
        id: moment(day.start).format('YYYY-MM-DD'),
        date: moment(day.start).toDate()
      })
      this.days.push({
        id: moment(day.end).format('YYYY-MM-DD'),
        date: moment(day.end).toDate()
      })
    },
    close () {
      this.aktivasiModal = false
      this.idOffer = null
    },
    hideAllCatatan () {
      if (this.statusPenawaran === 'Belum Dibayar') {
        return 'is-hidden'
      }
    },
    getClass () {
      if (this.status === 'Active' ||
        this.status === 'Completed' ||
        this.status === 'Cancelled' || this.status === 'Belum Dibayar') {
        return 'is-hidden'
      }
    },
    selectTab (val) {
      this.activeTab = val
    },
    roleAccess (role) {
      if (this.roles === null) { return true }

      let roleCheck = false
      if (this.roles !== role) {
        roleCheck = true
      }
      return roleCheck
    },
    menuAccess (path) {
      if (this.roles === null) {
        return true
      }
      let check = true
      this.rolePath.forEach((row) => {
        if (row.controller_path === path) {
          check = false
        }
      })
      return check
    },
    clearInput () {
      this.notesProyek = null
    },
    showAktivasiModal (id) {
      this.aktivasiModal = true
      this.offerId = id
    },
    convDate (date) {
      return moment(date).format('DD MMMM YYYY')
    },
    back () {
      this.$router.push({ name: 'Penawaran' })
    },
    getDetails (idPenawaran) {
      try {
        if (idPenawaran === null) { return }
        const data = { url: this.url, id: idPenawaran }
        const loadingComponent = this.$buefy.loading.open({
          container: this.isFullPage ? null : this.$refs.element
        })
        this.$store.dispatch('penawaran/detailOffer', data)
        setTimeout(() => {
          loadingComponent.close()
        }, 1 * 1000)
      } catch (error) {
        throw new Error(error)
      }
    },
    submitAktivasi () {
      try {
        // total hari dari penawaran
        const durationOffer = this.detail.duration

        // total hari yang dipilih
        const sortDate = this.days.slice().sort((a, b) => new Date(a.date) - new Date(b.date))
        const startDate = sortDate[0].date
        const endDate = sortDate[this.days.length - 1].date
        const dateCount = moment(endDate).diff(moment(startDate), 'days') + 1

        if (dateCount < durationOffer) {
          this.$buefy.toast.open({
            duration: 4000,
            message: 'Jadwal pengerjaan project kurang dari jadwal penawaran!',
            position: 'is-bottom',
            type: 'is-danger'
          })
          return
        }

        const data = {
          url: this.url,
          offer_id: this.offerId,
          start_date: this.days[0].id,
          end_date: this.days[1].id,
          estimateCompleted: this.estimateCompleted
        }

        if (data) {
          this.$store.dispatch('penawaran/aktivasiOffer', data)
            .then((response) => {
              const loadingComponent = this.$buefy.loading.open({
                container: this.isFullPage ? null : this.$refs.element
              })
              this.aktivasiModal = false
              setTimeout(() => {
                this.$buefy.toast.open({
                  duration: 2000,
                  message: response.data.message,
                  position: 'is-bottom',
                  type: 'is-success'
                })
                loadingComponent.close()
                this.getDetails(this.offerId)
                this.days = []
              }, 2 * 1000)
            }).catch((error) => {
              this.aktivasiModal = false
              this.days = []
              this.estimateCompleted = null
              this.$buefy.toast.open({
                duration: 4000,
                message: error.response.data.message,
                position: 'is-bottom',
                type: 'is-danger'
              })
              return false
            })
        }
      } catch (error) {
        throw new Error(error)
      }
    }
  },
  mounted () {},
  created () {
    this.dateMin()
    guard.check(this.$router, this.$router.currentRoute.path)
    this.getDetails(this.$route.params.idPenawaran)
    this.title = this.$route.params.source
  }
}
</script>

<style lang="scss" scoped>
.back-btn {
  cursor: pointer;
}

</style>
